import moment from "moment/moment";

// Update timer display with current duration
function setTimer(el, startTime) {
  if (!el) return;
  
  const now = moment();
  const diff = now.diff(startTime);
  const duration = moment.duration(diff);
  
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  
  el.textContent = `${years}y ${months}m ${days}d ${hours}h ${minutes}m ${seconds}s`;
  setTimeout(() => setTimer(el, startTime), 1000);
}

// Find element by text content
function getElByText(text) {
  return Array.from(document.getElementsByTagName("span"))
    .find(el => el.textContent?.includes(text));
}

// Set up timers for different experience sections
export function setExperienceTimers() {
  const timers = [
    { text: 'total experience counting...', start: '2018-09-01 08:00:00' },
    { text: 'ai experience counting...', start: '2024-02-01 00:00:00' },
    { text: 'flutter experience counting...', start: '2022-02-01T00:00:00+09:00' },
    { text: 'cordova experience counting...', start: '2019-12-09 09:00:00' },
    { text: 'frontend experience counting...', start: '2018-01-01 00:00:00' },
    { text: 'backend experience counting...', start: '2018-09-01 00:00:00' },
    { text: 'devops experience counting...', start: '2019-01-01 00:00:00' }
  ];
  
  timers.forEach(timer => {
    const el = getElByText(timer.text);
    if (el) setTimer(el, moment(timer.start));
  });
}

// Create link element
function makeLink(href, text) {
  const a = document.createElement('a');
  a.href = href;
  a.target = '_blank';
  a.textContent = text;
  a.classList.add('hljs-string');
  return a;
}

// List of features to be replaced with interactive elements
export const features = [
  {
    text: 'personalEmail',
    feature: makeLink('mailto:grzegorz.hul@gmail.com', 'grzegorz.hul@gmail.com'),
  },
  {
    text: 'id=pl.librus.teacher',
    feature: makeLink('https://play.google.com/store/apps/details?id=pl.librus.teacher&hl=pl&gl=US&pli=1', 'Google Play'),
  },
  {
    text: 'librus-nauczyciel/id1591474710?l=pl',
    feature: makeLink('https://apps.apple.com/pl/app/librus-nauczyciel/id1591474710?l=pl', 'App Store'),
  },
  {
    text: 'id=com.holyseepavilion',
    feature: makeLink('https://play.google.com/store/apps/details?id=com.holyseepavilion&hl=pl&gl=US', 'Google Play'),
  },
  {
    text: 'id=pl.devgreg.neo_psalms',
    feature: makeLink('https://play.google.com/store/apps/details?id=pl.devgreg.neo_psalms&hl=pl&gl=US', 'Google Play'),
  },
  {
    text: 'id=pl.librus.synergiaDru2',
    feature: makeLink('https://play.google.com/store/apps/details?id=pl.librus.synergiaDru2', 'Google Play'),
  },
  {
    text: 'librus/id1122457592?l=pl',
    feature: makeLink('https://apps.apple.com/pl/app/librus/id1122457592?l=pl', 'App Store'),
  },
  {
    text: 'id=pl.devgreg.hodowla',
    feature: makeLink('https://play.google.com/store/apps/details?id=pl.devgreg.hodowla&pli=1', 'Google Play'),
  },
]

// Replace text with interactive feature
export function replaceTextWithFeature(text, feature) {
  const el = getElByText(text);
  if (el) {
    el.textContent = '';
    el.appendChild(feature);
  }
}

// Add collapse button to container
export function addCollapseButton(getText, isCollapsed, onClick) {
  const container = getElByText(getText);
  if (!container) return;
  
  container.classList.add('collapse-container');
  const button = document.createElement('button');
  button.textContent = isCollapsed ? '+' : '-';
  button.classList.add('collapse-button');
  button.addEventListener('click', onClick);
  container.appendChild(button);
}
