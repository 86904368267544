import 'highlight.js/styles/tokyo-night-dark.min.css';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import {features, replaceTextWithFeature, setExperienceTimers} from './helper.js';

// Initialize variables for collapsible sections
let mainTechStackCollapsed = false;
let flutterProjectsCollapsed = false;
let cordovaProjectsCollapsed = false;
let aiSkillsCollapsed = false;
let frontendSkillsCollapsed = false;
let backendSkillsCollapsed = false;
let devopsSkillsCollapsed = false;

// Generate the JSON data model
function generateJsonData() {
  return {
    name: 'Grzegorz',
    surName: 'Hul',
    profession: 'AI Enthusiast & Full-Stack Developer | Mobile & Frontend Specialist',
    email: 'personalEmail',
    summary: 'Experienced developer focused on creating high-performance applications across web and mobile platforms, with additional expertise in AI integration and DevOps.',
    totalCommercialExperienceTime: 'total experience counting...',
    mainTechStack: mainTechStackCollapsed ? '...' : [
      {
        name: 'AI & Machine Learning',
        experienceTime: 'ai experience counting...',
        isExperienceCommercial: 'true',
        isTechPreferable: 'true',
        skills: aiSkillsCollapsed ? '...' : [
          'Local LLM deployments', 
          'Ollama', 
          'TTS models', 
          'AI-assisted development (Cursor)',
          'Custom AI agent development (with/without frameworks)',
          'Python for AI applications',
          'AI server configuration and optimization (with RTX 3090)'
        ]
      },
      {
        name: 'Flutter',
        experienceTime: 'flutter experience counting...',
        isExperienceCommercial: 'true',
        isTechPreferable: 'true',
        usedLibrariesOrPlugins: [
          'firebase', 'bloc', 'floor', 'go_router', 'intl', 'webview_flutter',
          'video_player', 'chewie', 'lottie', 'and many more...',
        ],
        flutterProjects: flutterProjectsCollapsed ? '...' : [
          {
            name: 'Teachers app for Librus',
            description: 'App for teachers to manage their school messages, calendar, courses, etc.',
            isCommercial: 'true',
            devTeamSize: '3',
            link: 'https://play.google.com/store/apps/details?id=pl.librus.teacher&hl=pl&gl=US&pli=1',
            link2: 'https://apps.apple.com/pl/app/librus-nauczyciel/id1591474710?l=pl',
          },
          {
            name: 'Holy See Pavilion',
            description: 'Mobile guide to the Holy See Pavilion at Expo 2020 Dubai UAE',
            isCommercial: 'false',
            devTeamSize: '1',
            link: 'https://play.google.com/store/apps/details?id=com.holyseepavilion&hl=pl&gl=US',
          },
          {
            name: 'Melodies of the morning psalms',
            description: 'Rather personal app with psalms and melodies for morning prayers',
            isCommercial: 'false',
            devTeamSize: '1',
            link: 'https://play.google.com/store/apps/details?id=pl.devgreg.neo_psalms&hl=pl&gl=US',
          },
        ],
      },
      {
        name: 'Cordova',
        experienceTime: 'cordova experience counting...',
        isExperienceCommercial: 'true',
        isTechPreferable: 'false',
        usedLibrariesOrPlugins: ['plenty'],
        cordovaProjects: cordovaProjectsCollapsed ? '...' : [
          {
            name: 'Parents and students app for Librus',
            description: 'App for parents and students to manage their school messages, calendar, grades, etc.',
            isCommercial: 'true',
            devTeamSize: '3',
            link: 'https://play.google.com/store/apps/details?id=pl.librus.synergiaDru2',
            link2: 'https://apps.apple.com/pl/app/librus/id1122457592?l=pl',
          },
          {
            name: 'Board game Farmer',
            description: 'Board game for 2-4 players written in React using css-grid',
            isCommercial: 'false',
            devTeamSize: '1',
            link: 'https://play.google.com/store/apps/details?id=pl.devgreg.hodowla&pli=1',
          },
        ],
      },
      {
        name: 'Frontend',
        experienceTime: 'frontend experience counting...',
        isExperienceCommercial: 'true',
        isTechPreferable: 'true',
        skills: frontendSkillsCollapsed ? '...' : [
          'JavaScript (ES6+)', 
          'Vue.js (Vuex)', 
          'HTML5', 
          'CSS3 (BEM)'
        ]
      },
      {
        name: 'Backend',
        experienceTime: 'backend experience counting...',
        isExperienceCommercial: 'true',
        isTechPreferable: 'true',
        skills: backendSkillsCollapsed ? '...' : [
          'Node.js (Express)',
          'MongoDB',
          'PostgreSQL (with vector extension)'
        ]
      },
      {
        name: 'DevOps',
        experienceTime: 'devops experience counting...',
        isExperienceCommercial: 'true',
        isTechPreferable: 'true',
        skills: devopsSkillsCollapsed ? '...' : [
          'Linux',
          'Docker'
        ]
      },
      {
        name: 'Workflow',
        skills: [
          'SCRUM methodology'
        ]
      }
    ],
  };
}

// Function to update the JSON display
function updateJsonDisplay() {
  try {
    const appElement = document.getElementById('app');
    if (!appElement) {
      console.error('App element not found');
      return;
    }
    
    const fullJson = generateJsonData();
    appElement.innerHTML = `<pre><code class='language-json'>${JSON.stringify(fullJson, null, 2)}</code></pre>`;
    
    hljs.highlightAll();
    
    setTimeout(applyInteractiveFeatures, 10);
  } catch (error) {
    console.error('Error updating JSON display:', error);
  }
}

// Apply interactive features
function applyInteractiveFeatures() {
  try {
    setExperienceTimers();
    features.forEach(({text, feature}) => replaceTextWithFeature(text, feature));
    addCollapseListeners();
  } catch (error) {
    console.error('Error applying interactive features:', error);
  }
}

// Add click handlers for collapsible sections
function addCollapseListeners() {
  addCollapseButton('mainTechStack', mainTechStackCollapsed, () => {
    mainTechStackCollapsed = !mainTechStackCollapsed;
    updateJsonDisplay();
  });
  
  addCollapseButton('flutterProjects', flutterProjectsCollapsed, () => {
    flutterProjectsCollapsed = !flutterProjectsCollapsed;
    updateJsonDisplay();
  });
  
  addCollapseButton('cordovaProjects', cordovaProjectsCollapsed, () => {
    cordovaProjectsCollapsed = !cordovaProjectsCollapsed;
    updateJsonDisplay();
  });
  
  // Add new collapse buttons for skill sections
  addCollapseButton('skills', aiSkillsCollapsed, () => {
    aiSkillsCollapsed = !aiSkillsCollapsed;
    updateJsonDisplay();
  });
  
  addCollapseButton('Frontend', frontendSkillsCollapsed, () => {
    frontendSkillsCollapsed = !frontendSkillsCollapsed;
    updateJsonDisplay();
  });
  
  addCollapseButton('Backend', backendSkillsCollapsed, () => {
    backendSkillsCollapsed = !backendSkillsCollapsed;
    updateJsonDisplay();
  });
  
  addCollapseButton('DevOps', devopsSkillsCollapsed, () => {
    devopsSkillsCollapsed = !devopsSkillsCollapsed;
    updateJsonDisplay();
  });
}

// Helper function to find elements by content and add collapse button
function addCollapseButton(text, isCollapsed, onClick) {
  const element = findElementByText(text);
  if (element) {
    const button = document.createElement('button');
    button.textContent = isCollapsed ? '+' : '-';
    button.classList.add('collapse-button');
    button.addEventListener('click', onClick);
    element.appendChild(button);
  }
}

// Helper function to find elements by content
function findElementByText(text) {
  const elements = document.querySelectorAll('span');
  for (const element of elements) {
    if (element.textContent.includes(text)) {
      return element;
    }
  }
  return null;
}

// Initialize the page
document.addEventListener('DOMContentLoaded', () => {
  hljs.registerLanguage('json', json);
  hljs.configure({ languages: ['json'] });
  updateJsonDisplay();
});
